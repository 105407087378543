import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { cliente } from '../../../classes/cliente';
import * as jsPDF from 'jspdf';
import * as html2canvas from "html2canvas"
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from '../../../classes/user';
import { OrderByPipe } from '../../pipes/order-by.pipe';
//import { OrderByDatePipe } from '../../pipes/orderOS.pipe';
import { DadosBancoService } from '../../services/dados-banco.service';
import { PorClienteService } from '../services/por-cliente.service';
import { EstoqueService } from '../services/estoque.service';
import { UserService } from "../../services/user/user.service";

import { first } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { MensagensComponent } from '../../dialogs/mensagens/mensagens.component';
import { isNgTemplate } from '@angular/compiler';
import * as env from "../../../../environments/environment";

import * as firebase from 'firebase';

@Component({
  selector: 'app-seleciona',
  templateUrl: './seleciona.component.html',
  styleUrls: ['./seleciona.component.css']
})

export class SelecionaComponent implements OnInit {
  ehValido: boolean = false;
  clientes: any[] = [];
  dataBase = '';
  ordemServico: any[] = [];
  mesesVigencia: any[] = [];
  gerado: boolean = false;
  mostrarRelatorioConsolidado: boolean = false;
  mostrarRelatorioSemCliente: boolean = false;
  mostrarRelatorioFatura: boolean = false;
  mostrarRelatorioFaturaVigencias: boolean = false;
  mostraRelatorioPorTecnico: boolean = false;
  mostraRelatorioClienteSemOS: boolean = false;
  mostraRelatorioPorSituacao: boolean = false;
  mostraRelatorioPorTecnicoFatur: boolean = false;
  mostraRelatorioEstoque: boolean = false;

  clienteData: cliente;
  totalHoras: number = 0;

  DescontoReal: number = 0;
  DescontoPerc: number = 0;
  usuAtivo: User;
  isMaster: boolean = false;
  ehSafari: boolean = false;
  ehFireFox: boolean = false;
  valorTotalRelatorioConsolidade: number = 0.0;
  esforcoTotalRelatorioConsolidade: number = 0;
  listaOrdemServicoGeral = [];
  totalHorasFaturaMensal = 0;
  totalValorFaturaMensal = 0;
  listaRelatorioFatura: {'mes': String, 'esforco': number, 'valorTotal': number, 'valorTotalFixos': number}[];
  //OSporTecnico: {tecnico: String, totalHoras: number, listaCliente: {codCliente: number, listaEsforcos: {item: any, paralelo: boolean}[]}[] }[] = [];
  relTecPorData: {tecnico: String, totalHoras: number, listaData: {data: String, totalHoras: number, listaEsforcos: {item: any, cliente: String, paralelo: boolean, codigoOS: number, situacao: string}[]}[] }[] = [];
  relTecPorCliente: {tecnico: String, valorTotal: number, horasTotal: number, horasTotalNot: number}[];
  ListaClientesSemOS: any[];
  RelPorSituacao: any[] = [];
  Situacoes: any[] = [];

  relEstoqueSaida = [];
  relEstoqueEntrada = [];
  totaisSaida: {'totalCusto': number, 'totalVenda': number, 'totalNeg': number} = {'totalCusto': 0.0, 'totalVenda': 0.0, 'totalNeg': 0.0};
  totaisEntrada: {'totalCusto': number, 'totalVenda': number} = {'totalCusto': 0.0, 'totalVenda': 0.0};

  valorMinimo: number = 75.0;
  valorPlantao: number = 120.0;
  valorMinPlantao: number = 75.0;
  dbRef: void;

  constructor(public serviceDados: DadosBancoService, 
              public db: AngularFireDatabase, 
              private router: Router, 
              private afAuth: AngularFireAuth, 
              private http: HttpClient,
              private userService: UserService,
              private dialogService: DialogService) {

    let hoje = moment();
    for (let i=0; i<13; i++){
        this.mesesVigencia.push({'vigencia': hoje.format('MM/YYYY'), 'desc': hoje.format('MMMM/YYYY')})
        hoje.month(hoje.month()-1);
    }
    this.mesesVigencia.reverse();

    afAuth.authState.subscribe((user: firebase.User) => {
      let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/usuarios`);
      this.db.object(dbRef).snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {

            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                  this.isMaster = this.usuAtivo.master;
                }  
              }

            });
            this.carregaCliente();
            this.carregaParamsGlobais();
      });

    });

    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        this.ehSafari = false;
      } else {
        this.ehSafari = true;
      }
    }else{
      if (ua.indexOf('firefox') > -1) {
        this.ehFireFox = true;
      }
    }

  }

  getDataBase() {
    if (this.dataBase == 'agb') {
      return 'agb-computadores';
    } else {
      return this.dataBase;
    }
  }

  carregaParamsGlobais() {
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/params-globais/valorMinimo/`);
    this.db.object(dbRef).snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      this.valorMinimo = result['value'];
    });
    
    dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/params-globais/valorPlantao/`);
    this.db.object(dbRef).snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      this.valorPlantao = result['value'];
    });
    
    dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/params-globais/valorMinPlantao/`);
    this.db.object(dbRef).snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      this.valorMinPlantao = result['value'];
    });    
  }

  ngOnInit() {
  }

  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = JSON.parse(localStorage.getItem("system-base")).base;
    }
    if (base == 'agb') {
      base = 'agb-computadores';
    }
    
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  private carregaCliente(){
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/clientes`);
    this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
            });
          }

          if (this.getDataBase() == 'drrafael') {
            let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/prospect`);
            this.db.object(dbRef).snapshotChanges().map(action => {
              const data = action.payload.toJSON();
              return data;
            }).subscribe(result => {
              if (result){
                Object.keys(result).map(key=> {
                      this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
                });
              }
            });
          }
    });
  }

  public reportFileName(tipo) {
    let cliente = '';
    if (this.clienteData) {
      cliente = this.clienteData.nomeFantasia + ' - ';
    }
    let month = moment().locale('pt-br').format('MMMM') + '.pdf';
    month = month[0].toUpperCase() + month.slice(1);
    return cliente + tipo + ' - ' + month;
  }


  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  agora(format){
    return moment().format(format);
  }

  primeiroDia(){
    return moment().date(1).format('YYYY-MM-DD');
  }
  ultimoDia(){
    return moment().endOf('month').format('YYYY-MM-DD');
  }

  public formataData(data){
    if (data.length > 10){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY');
    } else {
      if (data.indexOf('-') > -1){
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      return data;
    }
  }

  imprimirPDF(relatorio){

    let printContents, popupWin;
    printContents = relatorio.innerHTML;
    let pdfName = this.clienteData.nomeFantasia + " " + moment().format('DD-MM HHmm');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
          <title>${pdfName}</title>
          <style>
            //........Customized style.......
          </style>
        </head>
        <body onload="window.print();window.close()">
          ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  
  public geraPDFConsolidado(){
    let pdfName = this.reportFileName('Relatorio Consolidado'); // 'Relatorio Consolidado ' + moment().format('DD-MM-YYYY HH-mm') + '.pdf';
    var doc = new jsPDF('p', 'in', 'a4');

    var verticalOffset = 0;

    doc.setFontSize(11);
    doc.text(0.2, verticalOffset + 0.3, this.agora('DD/MM/YYYY'));

    doc.setFontSize(23);
    doc.text(2.6, 0.9, 'Relatório consolidado');

    verticalOffset = 1;
    this.listaOrdemServicoGeral.forEach((item) => {

      if (item.valorTotal > 0){
        verticalOffset += 0.2;
        doc.setLineWidth(0.009);
        doc.setFillColor(255,255,255);
        doc.setDrawColor(196,196,196);
        doc.roundedRect(0.2, verticalOffset, 7.8, 0.8, 0.02, 0.02, 'FD');

        doc.setFontSize(12);
        doc.setFontType('bold');
        verticalOffset += 0.26;
        doc.text(0.35, verticalOffset, item.obj.clienteData().nomeFantasia);
        doc.setFontType('normal');
        doc.setFontSize(10);
        verticalOffset += 0.20;
        doc.text(0.35, verticalOffset, 'Qtd. Horas: ' + this.minutosParaHoras(item.esforcoTotal) + ' - Valor: ' + item.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));

        if (verticalOffset+0.7 >= 11){
          doc.addPage();
          verticalOffset = 0.3;
        }
      }
    });

    doc.setLineWidth(0.009);
    doc.setFillColor(255,255,255);
    doc.setDrawColor(0,0,0);
    verticalOffset += 0.3;
    doc.roundedRect(0.2, verticalOffset, 7.8, 1.2, 0.02, 0.02, 'FD');

    doc.setFontSize(15);
    doc.setFontType('bold');
    verticalOffset += 0.28;
    doc.text(0.35, verticalOffset, 'Total');

    doc.setFontType('normal');
    doc.setFontSize(12);

    verticalOffset += 0.35;
    doc.text(0.35, verticalOffset, 'Total ' + this.valorTotalRelatorioConsolidade.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
    verticalOffset += 0.22;
    doc.text(0.35, verticalOffset, 'Total horas: ' + this.minutosParaHoras(this.esforcoTotalRelatorioConsolidade));
    verticalOffset += 0.22;
    doc.text(0.35, verticalOffset, 'Quantidade de OS: ' + this.listaOrdemServicoGeral.length);

    doc.save(pdfName);
  }



  getDataAtendimento(itemOS){
    if (itemOS.listaEsforco && JSON.parse(itemOS.listaEsforco).length == 1) {
      return JSON.parse(itemOS.listaEsforco)[0].data;
    } else {
      if (itemOS.dataEsforco) {
        return itemOS.dataEsforco;
      } else {
        return itemOS.dataFim;
      }
    }
  }



  public formataEsforcoNeg(esforco){
    if (esforco < 0){
      esforco = Math.floor(Math.abs(esforco));
    }
    return this.formataEsforco(esforco);
  }

  private minutosParaHoras(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }

  public formataEsforco(esforco){
    /*let formatarEsforco = moment('00:00', 'HH:mm');
    formatarEsforco.minutes(esforco);
    return formatarEsforco.format('HH:mm');*/

    let horas = Math.floor(esforco / 60);
    let minutes = esforco % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }


  public getClienteIndex(lista, codigo){
    let indexCli = -1;
    lista.forEach((cliente, index) => {
      if (cliente.data.codCliente == codigo){
        indexCli =  index;
      }
    });
    return indexCli;
  }

  public getClienteCodigo(lista, nome){
    let codCliente = 0;
    lista.forEach((cliente, index) => {
      if (cliente.data.nomeFantasia.toLowerCase() == nome.toLowerCase()){
        codCliente =  cliente.data.codCliente;
      }
    });
    return codCliente;
  }

  public getClienteData(nome){
    let iCliente;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.nomeFantasia.toLowerCase() == nome.toLowerCase()){
        iCliente =  cliente.data;
      }
    });
    return iCliente;
  }

  public getClienteDataPorCodigo(codigo){
    let iCliente;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codigo){
        iCliente =  cliente;
      }
    });
    return iCliente;
  }

  public verificaCliente(valor){
    if (valor) {
      return this.getClienteCodigo(this.clientes, valor) != 0;
    } else {
      return false;
    }
  }

  public geraRelatorioFatura(form){
    if (form.selVigencia.value == 0){
      alert('Selecione a vigência da fatura.');
      return
    }
    if (form.selVigenciaFinal.value == 0){
      alert('Selecione a vigência Final da fatura.');
      return
    }

    this.gerado = false;
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostraRelatorioPorTecnico = false;
    this.mostraRelatorioPorTecnicoFatur = false;
    this.mostrarRelatorioFatura = false;
    this.mostrarRelatorioFaturaVigencias = false;
    this.mostraRelatorioEstoque = false;


    let dataIni = moment(form.selVigencia.value, 'MM/YYYY');
    dataIni.date(1);
    let dataFim = moment(form.selVigenciaFinal.value, 'MM/YYYY');
    dataFim.endOf('month');

    if (dataIni.isAfter(dataFim)) {
      alert('Inicio deve ser antes do fim.');
      return
    }

    if (form.selCliente && form.selCliente.value == ""){
      this.relatorioSemCliente(form, dataIni.format('YYYY-MM-DD'), dataFim.format('YYYY-MM-DD'), form.selVigencia.value);
      this.mostrarRelatorioFatura = true;
    } else {
      if (form.selCliente && this.verificaCliente(form.selCliente.value)){
          this.relatorioPorCliente(form, dataIni.format('YYYY-MM-DD'), dataFim.format('YYYY-MM-DD'), false);
          this.mostrarRelatorioFaturaVigencias = true;
      } else {
          alert('Cliente não cadastrado.');
      }
    }
  }

  public geraRelatorio(form){
    this.gerado = false;
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostraRelatorioPorTecnico = false;
    this.mostraRelatorioPorTecnicoFatur = false;
    this.mostrarRelatorioFatura = false;
    this.mostrarRelatorioFaturaVigencias = false;
    this.mostraRelatorioEstoque = false;

    if (form.selCliente && form.selCliente.value == ""){
      this.relatorioSemCliente(form, form.edtDataInicio.value, form.edtDataFim.value, 0);
      this.mostrarRelatorioSemCliente = true;
    } else {
      if (form.selCliente && this.verificaCliente(form.selCliente.value)){
          this.relatorioPorCliente(form, form.edtDataInicio.value, form.edtDataFim.value, true);
      } else {
          alert('Cliente não cadastrado.');
      }
    }
  }

  public deepIndexOf(arr, obj) {
    return arr.findIndex(function (cur) {
      return Object.keys(obj).every(function (key) {
        return obj[key] === cur[key];
      });
    });
  }

  private situacaoComValor(situacao){
    let situacoes = this.serviceDados.getSituacoes();
    for (let item of situacoes){
      if (situacao.toLowerCase() == item.situacao.toLowerCase()){
        return item.valor;
      }
    }
    return undefined;
  }

  public async relatorioPorCliente(form, dataIniValue, dataFimValue, geraPDF){

    let resp = await this.loadClosedOSInterval(dataIniValue, dataFimValue)
    .then(result => {
         //let codCli = this.getClienteCodigo(this.clientes, form.selCliente.value);
         let dataCli = this.getClienteData(form.selCliente.value)
         let dataIni = moment(dataIniValue, 'YYYY-MM-DDTHH:mm');
         let dataFim = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
         dataFim.hours(23);
         dataFim.minutes(59);

         var listaOrdemServico = [];
         let totalHorasConsumidas = 0;
         let totalHorasConsumidas_Cobrar = 0;
         let totalHorasPlano = 0;
         let totalHorasConsumidasPlantao = 0;
         let totalHorasPlanoPlantao = 0;
         let oneMonthBefore = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
         oneMonthBefore.subtract(1, 'months').endOf('month');
         //let valorNoturnoPorMes = [0,0,0,0,0,0,0,0,0,0,0,0];
         //let horasNoturnoPorMes = [0,0,0,0,0,0,0,0,0,0,0,0];
         Object.keys(result).map(date=> {
           Object.keys(result[date]).map(key => {
              

              let listaEmpresas = Object.assign([], dataCli.listaEmpresas);
              if (result[date][key].codCliente == dataCli.codCliente || listaEmpresas.map((item) => item.cod).indexOf(result[date][key].codCliente) > -1){

                // -----------------------------------------------
                // Calcul horas de contrato 
                // -----------------------------------------------
                //dataCli.qtdHoras
                let values = this.calcHorasContrato(dataCli, dataFimValue, dataFim, result[date][key], totalHorasConsumidas, totalHorasConsumidas_Cobrar)
                totalHorasPlano = values.totalHorasPlano;
                totalHorasConsumidas = values.totalHorasConsumidas;
                totalHorasConsumidas_Cobrar = values.totalHorasConsumidas_Cobrar;
                // -----------------------------------------------
                // Calcul horas de contrato plantão
                // -----------------------------------------------
                let valuesPlantao = this.calcHorasContratoPlantao(dataCli, dataFimValue, dataFim, result[date][key], totalHorasConsumidasPlantao);
                totalHorasPlanoPlantao = valuesPlantao.totalHorasPlano;
                totalHorasConsumidasPlantao = valuesPlantao.totalHorasConsumidas;

                let dataOS = moment(result[date][key].dataFim, 'YYYY-MM-DDTHH:mm');
                if (dataOS.isBefore(dataIni) && (totalHorasConsumidas_Cobrar / 60) > totalHorasPlano) {
                  totalHorasConsumidas_Cobrar = totalHorasPlano * 60;
                } 

                // -------------------------
                if (dataOS.isBetween(dataIni, dataFim)) {
                  let dataCliOS = this.getClienteDataPorCodigo(result[date][key].codCliente)
                  listaOrdemServico.push({ 'key': key, 'data':result[date][key], 'clienteData': dataCliOS.data, 'dataAtendimento': this.formataData(this.getDataAtendimento(result[date][key])) });
                }
              }
           })
         });

         if (geraPDF){
           var xRelatorioPorCliente = new PorClienteService(this.serviceDados, listaOrdemServico, dataCli, 0, this.valorMinimo, this.valorPlantao,
                                                            this.valorMinPlantao, this.getDadosPlano(dataCli, totalHorasPlano, totalHorasConsumidas, totalHorasConsumidas_Cobrar),
                                                            this.getDataBase(), this.getDadosPlanoPlantao(dataCli, totalHorasPlanoPlantao, totalHorasConsumidasPlantao));
           if (form.edtDescontoReal && form.edtDescontoReal.value){
              xRelatorioPorCliente.descontoReal = form.edtDescontoReal.value;
           }
           if (form.edtDescontoPerc && form.edtDescontoPerc.value){
              xRelatorioPorCliente.descontoPerc = form.edtDescontoPerc.value;
           }

            xRelatorioPorCliente.geraPDF('Relatório Atendimentos');
         } else {
            this.listaRelatorioFatura = [];
            listaOrdemServico.forEach((item) => {
                let dataOS = moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm');
                let index = this.deepIndexOf(this.listaRelatorioFatura, {'mes': dataOS.format('MM/YYYY')});
                let valorFixo = this.situacaoComValor(item.data.situacao);
                if (index > -1){

                    if (valorFixo != 0){
                      this.listaRelatorioFatura[index].esforco += item.data.esforco;
                      if (valorFixo){
                        this.listaRelatorioFatura[index].valorTotalFixos += valorFixo;
                      } else {
                        if (this.listaRelatorioFatura[index].esforco > (item.data.clienteQtdHoras*60)){
                          let diff = this.listaRelatorioFatura[index].esforco - (item.data.clienteQtdHoras*60);
                          this.listaRelatorioFatura[index].valorTotal = (diff * (item.data.clienteValorHr/60));
                        }
                      }
                    }
                } else {
                    let esforco = 0;
                    if (valorFixo != 0){
                      esforco = item.data.esforco;
                    }
                    this.listaRelatorioFatura.push({'mes': dataOS.format('MM/YYYY'), 'esforco': esforco, 'valorTotal': 0, 'valorTotalFixos': 0});
                }
            })
         }

         return resp;
       });
  }

  public salvaPDF(index){
    this.listaOrdemServicoGeral[index].obj.geraPDF('Relatório Atendimentos');
  }
  public salvaPDFComSituacao(index){
    this.listaOrdemServicoGeral[index].obj.geraPDF('Relatório Atendimentos', true);
  }

  // -----------------------------------------------
  // Calcul horas de contrato 
  // -----------------------------------------------
  public calcHorasContrato(dataCli, dataFimValue, dataFimMoment, OSData, totalHorasConsumidas, totalHorasConsumidas_Cobrar) {
    let result = {
      totalHorasPlano: 0, 
      totalHorasConsumidas: totalHorasConsumidas,
      totalHorasConsumidas_Cobrar: totalHorasConsumidas_Cobrar
    }
    if (dataCli.contrato && dataCli.tipoContrato && dataCli.tipoContrato != 'Mensal') {
      let dataFimAux = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
      dataFimAux.hours(23);
      dataFimAux.minutes(59);

      let mesesDesdeInicio = dataFimMoment.diff(moment(dataCli.inicioContrato, 'YYYY-MM-DD'), 'months')+1;
      let mesesInicioContrato = 0;
      let iniContrato;
      let fimContrato;
      if (dataCli.tipoContrato == 'Semestral') {
        if (mesesDesdeInicio % 6 == 0) {
          mesesInicioContrato = 6;
        } else {
          mesesInicioContrato = mesesDesdeInicio % 6;
        }  
        result.totalHorasPlano = dataCli.qtdHoras * 6;

        iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
        fimContrato = moment(iniContrato).add(5, 'months');
      } else {
        if (dataCli.tipoContrato == 'Trimestral') {
          if (mesesDesdeInicio % 3 == 0) {
            mesesInicioContrato = 3;
          } else {
            mesesInicioContrato = mesesDesdeInicio % 3;
          }  
          result.totalHorasPlano = dataCli.qtdHoras * 3;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(2, 'months');
        } else {
          if (dataCli.tipoContrato == 'Anual') {
            if (mesesDesdeInicio % 12 == 0) {
              mesesInicioContrato = 12;
            } else {
              mesesInicioContrato = mesesDesdeInicio % 12;
            }  
            result.totalHorasPlano = dataCli.qtdHoras * 12;
  
            iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
            fimContrato = moment(iniContrato).add(11, 'months');
          } 
        }                     
      }
      
      iniContrato.date(1);
      iniContrato.hours(0);
      iniContrato.minutes(1);      
      fimContrato = fimContrato.endOf('month');
      let dataOSFim = moment(OSData.dataFim, 'YYYY-MM-DDTHH:mm');

      if (dataOSFim.isBetween(iniContrato, fimContrato)) {
        let valorFixo = this.situacaoComValor(OSData.situacao);
        if (!valorFixo && valorFixo != 0 && !OSData.isentar){
          result.totalHorasConsumidas += OSData.esforco;  
          result.totalHorasConsumidas_Cobrar += OSData.esforco;
        }                   
      }
    }

    return result;
  }

  // -----------------------------------------------
  // Calcul horas de contrato plantão
  // -----------------------------------------------
  public calcHorasContratoPlantao(dataCli, dataFimValue, dataFimMoment, OSData, totalHorasConsumidas) {

    let result = {
      totalHorasPlano: 0, 
      totalHorasConsumidas: totalHorasConsumidas
    }
    if (dataCli.contratoPlantao && dataCli.tipoContratoPlantao && dataCli.tipoContratoPlantao != 'Mensal') {
      let dataFimAux = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
      dataFimAux.hours(23);
      dataFimAux.minutes(59);

      let mesesDesdeInicio = dataFimMoment.diff(moment(dataCli.inicioContratoPlantao, 'YYYY-MM-DD'), 'months')+1;
      let mesesInicioContrato = 0;
      let iniContrato;
      let fimContrato;
      if (dataCli.tipoContratoPlantao == 'Semestral') {
          if (mesesDesdeInicio % 6 == 0) {
            mesesInicioContrato = 6;
          } else {
            mesesInicioContrato = mesesDesdeInicio % 6;
          }  
          result.totalHorasPlano = dataCli.qtdHorasPlantao * 6;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(5, 'months');                      
      } else {
        if (dataCli.tipoContratoPlantao == 'Trimestral') {
          if (mesesDesdeInicio % 3 == 0) {
            mesesInicioContrato = 3;
          } else {
            mesesInicioContrato = mesesDesdeInicio % 3;
          }  
          result.totalHorasPlano = dataCli.qtdHorasPlantao * 3;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(2, 'months');
        }                      
      }
      
      iniContrato.date(1);
      fimContrato = fimContrato.endOf('month');
      let dataOSFim = moment(OSData.dataFim, 'YYYY-MM-DDTHH:mm');

      if (dataOSFim.isBetween(iniContrato, fimContrato)) {
        let valorFixo = this.situacaoComValor(OSData.situacao);
        if (!valorFixo && valorFixo != 0 && !OSData.isentar){
          result.totalHorasConsumidas += OSData.esforco;  
        }                   
      }
    }
    return result;
  }

  public getDadosPlano(dataCli, totalHorasPlano, totalHorasConsumidas, totalHorasConsumidas_Cobrar) {
    let saldoHoras = 0;
    let dadosPlano = undefined;

    if (totalHorasPlano > 0) {
      saldoHoras = (totalHorasPlano*60) - totalHorasConsumidas;
      if (saldoHoras < 0) {
        saldoHoras = 0;
      }
      dadosPlano = {
          saldoHorasContrato: saldoHoras, 
          totalHorasConsumidas: totalHorasConsumidas, 
          tipoContrato: dataCli.tipoContrato, 
          totalHoras: totalHorasPlano,
          totalHorasConsumidas_Cobrar: totalHorasConsumidas_Cobrar
      }
    }

    return dadosPlano;
  }

  public getDadosPlanoPlantao(dataCli, totalHorasPlanoPlantao, totalHorasConsumidasPlantao) {
    let saldoHorasPlantao = 0;
    let dadosPlanoPlantao = undefined;

    if (totalHorasPlanoPlantao > 0) {
      saldoHorasPlantao = (totalHorasPlanoPlantao*60) - totalHorasConsumidasPlantao;
      if (saldoHorasPlantao < 0) {
        saldoHorasPlantao = 0;
      }
      dadosPlanoPlantao = {saldoHorasContrato: saldoHorasPlantao, totalHorasConsumidas: totalHorasConsumidasPlantao, tipoContrato: dataCli.tipoContratoPlantao, totalHoras: totalHorasPlanoPlantao}
    } else {
      if (dataCli.tipoContratoPlantao == 'Mensal') {
        saldoHorasPlantao = -1;
      }  
    }    

    return dadosPlanoPlantao;
  }
  

  async getUserData() {
    return await this.afAuth.authState.pipe(first()).toPromise();;
  }

  async getUserToken() {
    let data = await this.getUserData()
    return data['pa'];
  }

  convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              let data = objs[item];
              data['id'] = item;
              return data;
            });
    }
    return [];
  }

  async loadClosedOSInterval(start, end) {
    let token = await this.getUserToken();
    const promises = [];

    let dateStart = moment(start).year(moment(start).year()-1);
    let dateEnd = moment(end);
    while (dateStart.isSameOrBefore(dateEnd)) {
      promises.push(this.http.get(`${this.getBaseURL()}/dados/ordemServico/concluidas/${dateStart.format('YYYY-MM-DD')}/.json?auth=${token}`).toPromise());
      dateStart.add(1, 'd');
    }

    let osList = [];
    let data = await Promise.all(promises);
    for (let index = 0; index < data.length; index++) {
      if (data[index] !== null) {
        osList.push(data[index])
      }
    }
    const obj = Object.assign({}, osList)    

    return obj;
  }

  public async relatorioSemCliente(form, dataInicioValue, dataFimValue, mesVigencia){

    let resp = await this.loadClosedOSInterval(dataInicioValue, dataFimValue)
    
    .then(result => {
      let dataIni = moment(dataInicioValue, 'YYYY-MM-DDTHH:mm');
      let dataFim = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
      dataFim.hours(23);
      dataFim.minutes(59);

      var listaOrdemServicoTodosClientes = [];
      var listaClientes = [];
      Object.keys(result).map(date=> {
        Object.keys(result[date]).map(key => {
            let dataOS = moment(result[date][key].dataFim, 'YYYY-MM-DDTHH:mm');

            //if (dataOS.isBetween(dataIni, dataFim)) {
              let clienteData = this.getClienteDataPorCodigo(result[date][key].codCliente);
              if (clienteData) {
                listaOrdemServicoTodosClientes.push({ 'key': key, 'data':result[date][key], clienteData: clienteData.data, 'dataAtendimento': this.formataData(this.getDataAtendimento(result[date][key])) });
                if (!listaClientes[result[date][key].codCliente] && !clienteData.data.ehGrupoEmpresa) {
                  listaClientes[result[date][key].codCliente] = [result[date][key].codCliente];
                  if (clienteData.data.listaEmpresas) {
                    let list = Object.assign([], clienteData.data.listaEmpresas);
                    listaClientes[result[date][key].codCliente] = listaClientes[result[date][key].codCliente].concat(list.map(cliente => cliente.cod))
                  }
                }
              }        
          });
        
      });   

      this.listaOrdemServicoGeral = [];
      this.totalHorasFaturaMensal = 0;
      this.totalValorFaturaMensal = 0;

      this.relTecPorCliente = [];
      listaClientes.forEach((codCliente, index) => {
        var listaOrdemServicoPorCliente = [];
        let totalHorasConsumidas = 0; 
        let totalHorasConsumidas_Cobrar = 0;
        let totalHorasPlano = 0;
        let totalHorasConsumidasPlantao = 0; 
        let totalHorasPlanoPlantao = 0;
        let oneMonthBefore = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
        oneMonthBefore.subtract(1, 'months').endOf('month');
        
        var xClienteData = this.getClienteDataPorCodigo(codCliente[0]);
        listaOrdemServicoTodosClientes.forEach((itemOS, index) => {
          if (codCliente.indexOf(itemOS.clienteData.codCliente) > -1){
            let dataOS = moment(itemOS.data.dataFim, 'YYYY-MM-DDTHH:mm');
            if (dataOS.isBetween(dataIni, dataFim) && !itemOS.data.clienteProjetos) {
              listaOrdemServicoPorCliente.push(itemOS);
            }  

            let dadosContratoCliente = xClienteData.data;
            if (codCliente.length == 1) {
              dadosContratoCliente = itemOS.clienteData;
            }
                  
            // -----------------------------------------------
            // Calcul horas de contrato 
            // -----------------------------------------------
            let values = this.calcHorasContrato(dadosContratoCliente, dataFimValue, dataFim, itemOS.data, totalHorasConsumidas, totalHorasConsumidas_Cobrar);
            totalHorasPlano = values.totalHorasPlano;
            totalHorasConsumidas = values.totalHorasConsumidas;
            totalHorasConsumidas_Cobrar = values.totalHorasConsumidas_Cobrar;
            // -----------------------------------------------
            // Calcul horas de contrato plantão
            // -----------------------------------------------
            let valuesPlantao = this.calcHorasContratoPlantao(dadosContratoCliente, dataFimValue, dataFim, itemOS.data, totalHorasConsumidasPlantao);
            totalHorasPlanoPlantao = valuesPlantao.totalHorasPlano;
            totalHorasConsumidasPlantao = valuesPlantao.totalHorasConsumidas;

            if (dataOS.isBefore(dataIni) && (totalHorasConsumidas_Cobrar / 60) > totalHorasPlano) {
              totalHorasConsumidas_Cobrar = totalHorasPlano * 60;
            } 
          }
        });


        // TOTAL HORAS NO SEMESTRE E VAI DESCONTANDO, quando ultrapassar as horas do mês começa cobrar
        // Nos relatórios mensais, deve apresentar os dados do plano
        if (xClienteData) {

            var xRelatorioPorCliente = new PorClienteService(this.serviceDados, listaOrdemServicoPorCliente, xClienteData, mesVigencia, 
                                                            this.valorMinimo, this.valorPlantao, this.valorMinPlantao, this.getDadosPlano(xClienteData.data, totalHorasPlano, totalHorasConsumidas, totalHorasConsumidas_Cobrar),
                                                            this.getDataBase(), this.getDadosPlanoPlantao(xClienteData.data, totalHorasPlanoPlantao, totalHorasConsumidasPlantao));
            if (form.edtDescontoReal && form.edtDescontoReal.value){
              xRelatorioPorCliente.descontoReal = form.edtDescontoReal.value;
            }
            if (form.edtDescontoPerc && form.edtDescontoPerc.value){
              xRelatorioPorCliente.descontoPerc = form.edtDescontoPerc.value;
            }

            var xValorTotalPorCliente = 0;
            var xHorasTotalPorCliente = 0;
            xRelatorioPorCliente.listaDadosRelatorio.forEach((item) => {
              xValorTotalPorCliente += item.valorTotalTipo;
              xHorasTotalPorCliente += item.esforcoTotalTipo;
            });

            
            if (xValorTotalPorCliente < this.valorMinimo && xValorTotalPorCliente > 0 && !xClienteData.data.contrato) {
              xValorTotalPorCliente = this.valorMinimo;
            }

            let xAuxHorasTotalPorCliente = 0;
            if (this.mostrarRelatorioConsolidado){             
              if (xHorasTotalPorCliente > (xClienteData.data.qtdHoras * 60)) {
                  xAuxHorasTotalPorCliente = xHorasTotalPorCliente - (xClienteData.data.qtdHoras * 60);
              } else {
                if (xHorasTotalPorCliente === (xClienteData.data.qtdHoras*60)) {
                  xAuxHorasTotalPorCliente = 0;
                }
              }
            } else {
              xAuxHorasTotalPorCliente = xHorasTotalPorCliente;
            }
            this.totalHorasFaturaMensal += xAuxHorasTotalPorCliente;
            this.totalValorFaturaMensal += xValorTotalPorCliente;
            this.listaOrdemServicoGeral.push({obj: xRelatorioPorCliente, valorTotal: xValorTotalPorCliente, esforcoTotal: xHorasTotalPorCliente}); 
        }
      });

      this.listaOrdemServicoGeral.sort((n1,n2) => {
        if (n1.obj.clienteData().nomeFantasia.toLowerCase() > n2.obj.clienteData().nomeFantasia.toLowerCase()) {
          return 1
        } else {
          if (n1.obj.clienteData().nomeFantasia.toLowerCase() < n2.obj.clienteData().nomeFantasia.toLowerCase()) {
            return -1
          } else {
            return 0;
          }
        }
      });
      this.gerado = true;
      if (this.mostrarRelatorioConsolidado){
        this.dadosConsolidados();
      }

     return resp;
    });

  }

  public dadosConsolidados(){
    this.valorTotalRelatorioConsolidade = 0;
    this.esforcoTotalRelatorioConsolidade = 0;
    this.listaOrdemServicoGeral.forEach((item) => {
      this.valorTotalRelatorioConsolidade += item.valorTotal;
      this.esforcoTotalRelatorioConsolidade += item.esforcoTotal;
    });
  }

  public mostraRelatorioConsolidado(form){
    this.mostrarRelatorioSemCliente = false;
    this.mostrarRelatorioFatura = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = true;
    this.mostraRelatorioPorTecnico = false;
    this.mostraRelatorioPorTecnicoFatur = false;
    this.mostraRelatorioEstoque = false;
    this.relatorioSemCliente(form, form.edtDataInicio.value, form.edtDataFim.value, 0);
  }

  public salvaRelatorioConsolidado(){
    this.geraPDFConsolidado();
  }


  // ############# FECHAMENTO MÊS ######################

  private saldoMesVigencia(cliente, vigenciaParam){
    let xReturn = 0;
    if (vigenciaParam != 0 && cliente.saldo){
      let vigencia = moment(vigenciaParam, 'MM/YYYY')
      vigencia.month(vigencia.month()-1);
      let saldos = JSON.parse(cliente.saldo);
      saldos.forEach((item) => {
        if (item.vigencia == vigencia.format('MM/YYYY')){
          xReturn = item.saldo;
        }
      });
    }

    return xReturn;
  }

  private verificaAtendimentoParalelo(lista, item, itemEsforco){
    let retorno = false;

    let inicio = moment(itemEsforco.data+itemEsforco.inicio, 'DD/MM/YYYYHH:mm');
    let fim = moment(itemEsforco.data+itemEsforco.fim, 'DD/MM/YYYYHH:mm');
    lista.forEach((itemTec) => {
      if (itemTec.tecnico == item.tecnico){
          if (itemTec.codCliente != item.codCliente ||
              itemTec.inicioEsforco != itemEsforco.inicio ||
              itemTec.fimEsforco != itemEsforco.fim ||
              itemTec.dataAtendimento != itemEsforco.data){

            let atendimentoInicio = moment(itemTec.dataAtendimento + "" + itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
            let atendimentoFim = moment(itemTec.dataAtendimento + "" + itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');

            if (atendimentoInicio.isBetween(inicio, fim) ||
                atendimentoFim.isBetween(inicio, fim) ||
                inicio.isBetween(atendimentoInicio, atendimentoFim) ||
                fim.isBetween(atendimentoInicio, atendimentoFim)){
              retorno = true;
            }
          }
      }

    });

    return retorno;
  }

  public async geraRelatorioTecnico(form){
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostrarRelatorioFatura = false;
    this.mostraRelatorioEstoque = false;
    let  dataInicioValue = form.edtDataInicioRelTecnico.value
    let dataFimValue = form.edtDataFimRelTecnico.value

    let OSporTecnico: {tecnico: String, dataAtendimento: String, codCliente: number, codigoOS: number, situacao: string, inicioEsforco: String, fimEsforco: String, paralelo: boolean}[] = [];

    let resp = await this.loadClosedOSInterval(dataInicioValue, dataFimValue)
    
    .then(result => {
      let dataIni = moment(form.edtDataInicioRelTecnico.value, 'YYYY-MM-DDTHH:mm');
      let dataFim = moment(form.edtDataFimRelTecnico.value, 'YYYY-MM-DDTHH:mm');
      dataFim.hours(23);
      dataFim.minutes(59);

      Object.keys(result).map(date=> {
        Object.keys(result[date]).map(key => {
          if (result[date][key].atividades) {
             for (let i =0; i < JSON.parse(result[date][key].atividades).length; i++ ){
               let atividade = JSON.parse(result[date][key].atividades)[i];
               atividade.esforcos.forEach((item) => {
                 let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                 if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){
                   OSporTecnico.push({'tecnico': item.tecnico, 'dataAtendimento': item.data, 'codCliente': result[date][key].codCliente, 'codigoOS': result[date][key].codigo, 'situacao': result[date][key].situacao, 'inicioEsforco': item.inicio, 'fimEsforco': item.fim, 'paralelo': false});
                 }
               });
             }
          }
        });
      });




      let dbRef = this.db.database.app.database(this.getBaseURL()).ref("/dados/ordemServico/abertas");
      let OSObservable = this.db.object(dbRef).snapshotChanges().map(action => {
        const data = action.payload.toJSON();
        return data;
      }).subscribe(result => {
        Object.keys(result).map(key=> {
          if (result[key].atividades) {
             for (let i =0; i < JSON.parse(result[key].atividades).length; i++ ){
               let atividade = JSON.parse(result[key].atividades)[i];
               atividade.esforcos.forEach((item) => {
                 let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                 if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){
                   OSporTecnico.push({'tecnico': item.tecnico, 'dataAtendimento': item.data, 'codCliente': result[key].codCliente, 'codigoOS': result[key].codigo, 'situacao': result[key].situacao, 'inicioEsforco': item.inicio, 'fimEsforco': item.fim, 'paralelo': false});
                 }
               });
             }
           }
        });
  
        OSporTecnico.sort((n1,n2) => {
          if (n1.tecnico > n2.tecnico) {
            return 1
          } else {
            if (n1.tecnico < n2.tecnico) {
              return -1
            }
          }
  
          if (n1.dataAtendimento > n2.dataAtendimento) {
            return 1
          } else {
            if (n1.dataAtendimento < n2.dataAtendimento) {
              return -1
            }
          }
  
          if (n1.inicioEsforco > n2.inicioEsforco) {
            return 1
          } else {
            if (n1.inicioEsforco < n2.inicioEsforco) {
             return -1
           }
          }
  
          if (n1.fimEsforco > n2.fimEsforco) {
            return 1
          } else {
            if (n1.fimEsforco < n2.fimEsforco) {
             return -1
           } else {
             return 0;
           }
          }
        });
  
        // Verifica atendimento paralelo
        // {tecnico: String, listaCliente: {codCliente: number, listaEsforcos: {item: any, paralelo: boolean}[]}[] }[] = [];
        //OSporTecnico: {tecnico: String, dataAtendimento: String, codCliente: number, inicioEsforco: String, fimEsforco: String, paralelo: boolean}[] = [];
        //let relTecPorData: {tecnico: String, totalHoras: number, listaData: {data: String, listaEsforcos: {item: any, cliente: number, paralelo: boolean}[]}[] }[] = [];
        this.relTecPorData = [];
        let tecCorrente: String = '';
        let dataCorrente: String = '';
        let indexTec = -1;
        let indexData = -1;
        OSporTecnico.forEach((itemTec) => {
          itemTec.paralelo = this.verificaAtendimentoParalelo(OSporTecnico, itemTec, {'data': itemTec.dataAtendimento, 'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco});
          let clienteData = this.getClienteDataPorCodigo(itemTec.codCliente);
  
          let clienteNome: String = '';
          if (clienteData) {
            clienteNome = clienteData.nome;
          } else {
            clienteNome = itemTec.tecnico;
          }
  
          if (itemTec.tecnico != 'Daniel Huebes ' && (clienteData || this.getDataBase() == 'gessner')) {
  
            if (tecCorrente != itemTec.tecnico){
              let inicio = moment(itemTec.dataAtendimento+''+itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
              let fim = moment(itemTec.dataAtendimento+''+itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');
              let minutes = moment.duration(fim.diff(inicio)).asMinutes();
  
              this.relTecPorData.push({'tecnico': itemTec.tecnico, 'totalHoras': minutes, 'listaData':
                                        [{'data': itemTec.dataAtendimento, 'totalHoras': minutes,'listaEsforcos':
                                        [{'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco},
                                          'cliente': clienteNome, 'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS,
                                          'situacao': itemTec.situacao}]
                                    }]
                                  });
              indexTec++;
              indexData = 0;
            } else {
              let inicio = moment(itemTec.dataAtendimento+''+itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
              let fim = moment(itemTec.dataAtendimento+''+itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');
              let minutes = moment.duration(fim.diff(inicio)).asMinutes();
  
              this.relTecPorData[indexTec].totalHoras += minutes;
              if (dataCorrente != itemTec.dataAtendimento){
                  this.relTecPorData[indexTec].listaData.push({'data': itemTec.dataAtendimento, 'totalHoras': minutes, 'listaEsforcos':
                                                              [{'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco},
                                                                'cliente': clienteNome, 'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS,
                                                                'situacao': itemTec.situacao}]
                                                          });
                  indexData++;
              } else {
                this.relTecPorData[indexTec].listaData[indexData].totalHoras += minutes;
                  this.relTecPorData[indexTec].listaData[indexData].listaEsforcos.push(
                            {'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco}, 'cliente': clienteNome,
                              'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS, 'situacao': itemTec.situacao});
              }
            }
            tecCorrente = itemTec.tecnico;
            dataCorrente = itemTec.dataAtendimento;
          }
  
        });
        this.mostraRelatorioPorTecnico = true;
        OSObservable.unsubscribe();
  
      });      
    });
  }

  // ###############################
  // ####### CLIENTE SEM OS ########
  // ###############################
  public async geraRelatorioClienteSemOS(form) {
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostrarRelatorioFatura = false;
    this.mostraRelatorioEstoque = false;
    this.mostraRelatorioPorTecnico = false;
    this.ListaClientesSemOS = this.clientes.slice(0);
    let  dataInicioValue = form.edtDataInicioRelClienteSemOS.value
    let dataFimValue = form.edtDataFimRelClienteSemOS.value

    for (let index = 0; index < this.ListaClientesSemOS.length; index++) {
        if (this.ListaClientesSemOS[index].data.ativo === false || this.ListaClientesSemOS[index].data.tipoCliente == 'Produto') {
          this.ListaClientesSemOS.splice(index, 1);
          index--;
        }      
    }

    let resp = await this.loadClosedOSInterval(dataInicioValue, dataFimValue)
    .then(result => {
         let dataIni = moment(form.edtDataInicioRelClienteSemOS.value, 'YYYY-MM-DDTHH:mm');
         let dataFim = moment(form.edtDataFimRelClienteSemOS.value, 'YYYY-MM-DDTHH:mm');
         dataFim.hours(23);
         dataFim.minutes(59);

          Object.keys(result).map(date=> {
            Object.keys(result[date]).map(key => {

              if (result[date][key].atividades) {
                for (let i =0; i < JSON.parse(result[date][key].atividades).length; i++ ){
                  let atividade = JSON.parse(result[date][key].atividades)[i];
                  atividade.esforcos.forEach((item) => {
                    let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                    if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){
                      let indexCli = this.getClienteIndex(this.ListaClientesSemOS, result[date][key].codCliente);
                      if (indexCli > -1) {
                        this.ListaClientesSemOS.splice(indexCli, 1);
                      }
                    }
                  });
                }
              } 
              
            })
          });

          this.mostraRelatorioClienteSemOS = true;
          return resp;
       });
  }

  // ##############################
  // ### Gera relatório Por Situação
  // ##############################
  public async geraRelatorioPorSituacao(form) {
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostraRelatorioPorSituacao = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostrarRelatorioFatura = false;
    this.mostraRelatorioEstoque = false;
    this.mostraRelatorioPorTecnico = false;
    this.RelPorSituacao = [];
    let  dataInicioValue = form.edtDataInicioRelPorSituacao.value
    let dataFimValue = form.edtDataFimRelPorSituacao.value

    let resp = await this.loadClosedOSInterval(dataInicioValue, dataFimValue)
    .then(result => {
      let dataIni = moment(form.edtDataInicioRelPorSituacao.value, 'YYYY-MM-DDTHH:mm');
      let dataFim = moment(form.edtDataFimRelPorSituacao.value, 'YYYY-MM-DDTHH:mm');
      dataFim.hours(23);
      dataFim.minutes(59);

      Object.keys(result).map(date=> {
        Object.keys(result[date]).map(key => {

          if (result[date][key].atividades) {
            for (let i =0; i < JSON.parse(result[date][key].atividades).length; i++ ){
              let atividade = JSON.parse(result[date][key].atividades)[i];
              atividade.esforcos.forEach((item) => {
                let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){                
                  this.RelPorSituacao.push({'situacao': result[date][key].situacao, 'data': result[date][key]} );
                  
                  if (this.Situacoes.indexOf(result[date][key].situacao) == -1) {
                    this.Situacoes.push(result[date][key].situacao);
                  }
                }
              });

            }
          }            
        })
      });

      this.RelPorSituacao.sort((a, b) => {
        if (a.situacao.toLowerCase() > b.situacao.toLowerCase()) {
          return 1;
        } else {
          if (a.situacao.toLowerCase() < b.situacao.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      this.mostraRelatorioPorSituacao = true;
         
    });    
  }

  getDataBySituation(situacao) {
    let data = [];
    this.RelPorSituacao.forEach((item) => {
      if (item.situacao == situacao) {
        let dataIndex = -1;
        data.forEach((data, index) => {
          if (item.data.nomeTecnico == data.responsavel) {
            dataIndex = index;
          }
        });

        if (dataIndex == -1) {
          data.push({situacao: item.situacao, esforco: item.data.esforco, responsavel: item.data.nomeTecnico, total: 1 });
        } else {
          data[dataIndex].esforco += item.data.esforco;
          data[dataIndex].total++;
        }
        
      }
    });

    return data;
  }

  getTotalBySituation(situacao) {
    let total = 0;
    this.RelPorSituacao.forEach((item) => {
      if (item.situacao == situacao) {
        total += item.data.esforco;
      }
    });

    return total;


  }
  // ################################
  // ##### TÉCNICO x FATURAMENTO ####
  // ################################

  public pegaItemClienteContrato(lista, codigo) {
    let itemIndex = -1;
    lista.forEach((item, index) => {
      if (item.clienteData.codCliente == codigo) {
        itemIndex = index;
      }
    })
    return itemIndex;
  }

  public async geraRelatorioTecnicoFatur(form){
    this.mostrarRelatorioSemCliente = false;
    this.mostraRelatorioClienteSemOS = false;
    this.mostrarRelatorioConsolidado = false;
    this.mostrarRelatorioFatura = false;
    this.mostraRelatorioEstoque = false;
    let  dataInicioValue = form.edtDataInicioRelEstoque.value
    let dataFimValue = form.edtDataFimRelEstoque.value

    let tecnicoPorCliente: {tecnico: String, codCliente: number, itemOS: any}[] = [];


    let resp = await this.loadClosedOSInterval(dataInicioValue, dataFimValue)
    .then(result => {
         //let codCli = 
         let dataIni = moment(form.edtDataInicioRelTecnicoFatur.value, 'YYYY-MM-DDTHH:mm');
         let dataFim = moment(form.edtDataFimRelTecnicoFatur.value, 'YYYY-MM-DDTHH:mm');
         dataFim.hours(23);
         dataFim.minutes(59);

         Object.keys(result).map(date=> {
           Object.keys(result[date]).map(key => {
             if (result[date][key].atividades) {

              let OSdataFim = moment(result[date][key].dataFim, 'YYYY-MM-DDTHH:mm');
               if ((result[date][key].esforco > 0 || result[date][key].esforcoNoturno > 0) &&
                  (result[date][key].nomeTecnico && OSdataFim.isBetween(dataIni, dataFim))) {
                    tecnicoPorCliente.push({'tecnico': result[date][key].nomeTecnico, 'codCliente': result[date][key].codCliente, 'itemOS': result[date][key]});
               }
              }
            });
         });


              tecnicoPorCliente.sort((n1,n2) => {
                if (n1.tecnico > n2.tecnico) {
                  return 1
                } else {
                  if (n1.tecnico < n2.tecnico) {
                    return -1
                  } else {
                    return 0;
                  }
                }
              });

              // Verifica atendimento paralelo
              // {tecnico: String, listaCliente: {codCliente: number, listaEsforcos: {item: any, paralelo: boolean}[]}[] }[] = [];
              //OSporTecnico: {tecnico: String, dataAtendimento: String, codCliente: number, inicioEsforco: String, fimEsforco: String, paralelo: boolean}[] = [];
              //let relTecPorData: {tecnico: String, totalHoras: number, listaData: {data: String, listaEsforcos: {item: any, cliente: number, paralelo: boolean}[]}[] }[] = [];
              this.relTecPorCliente = [];
              let tecCorrente: String = '';              
              let indexTec = -1;       
              let listaClietensContrato = [];      
              
              tecnicoPorCliente.forEach((itemTec) => {
                 // {'tecnico': item.tecnico, 'codCliente': result[key].codCliente, 'itemOS': result[key]}
                
                if (!itemTec.itemOS.isentar) {
                  let clienteData = this.getClienteDataPorCodigo(itemTec.codCliente);
                  
                  //console.log(itemTec.itemOS.codigo + " - " + itemTec.itemOS.esforco);
                  if (clienteData) {

                    let valor = 0;
                    let horasTotal = 0;
                    let horasTotalNot = 0;
                    let horasXcontrato = 0;
                    let valorFixo = this.situacaoComValor(itemTec.itemOS.situacao);
                    if (valorFixo && !clienteData.data.contrato){
                      if (valorFixo != 0) {
                        valor = +valorFixo;
                      }                    
                    } else {
                      if (clienteData.data.contrato) {
                        let clienteDataQtdMin = clienteData.data.qtdHoras * 60;
                        let itemIndex = this.pegaItemClienteContrato(listaClietensContrato, clienteData.data.codCliente);
                        if (itemIndex > -1) {
                          if (clienteDataQtdMin < listaClietensContrato[itemIndex].totalHoras) { 
                            horasXcontrato = +itemTec.itemOS.esforco;
                            listaClietensContrato[itemIndex].totalHoras += +itemTec.itemOS.esforco;
                          } else {
                            listaClietensContrato[itemIndex].totalHoras += +itemTec.itemOS.esforco;
  
                            if (clienteDataQtdMin < listaClietensContrato[itemIndex].totalHoras) {
                              horasXcontrato = listaClietensContrato[itemIndex].totalHoras - clienteDataQtdMin;
                            }
                          }                          
                        } else {
                          listaClietensContrato.push({'clienteData': clienteData.data, 'totalHoras': itemTec.itemOS.esforco});
                          if (clienteDataQtdMin < itemTec.itemOS.esforco) {
                            horasXcontrato = itemTec.itemOS.esforco - clienteDataQtdMin;
                          }                      
                        }
                      } else {
                        horasXcontrato = itemTec.itemOS.esforco;
                        console.log(horasXcontrato);
                      }
    
                      if (horasXcontrato > 0){                      
                        horasTotal += horasXcontrato;
                        valor += +(horasXcontrato * (itemTec.itemOS.clienteValorHr /60));                      
                      }
                      if (itemTec.itemOS.esforcoNoturno > 0) {     
  
                        let clienteValorHrNot = 0;
                        if (moment(itemTec.itemOS.dataInicio, "YYYY-MM-DDTHH:mm").isBefore(moment('01/02/2019', 'DD/MM/YYYY'))){
                          clienteValorHrNot = 115;//itemOS.data.clienteValorHrNot ? itemOS.data.clienteValorHrNot : (itemOS.data.clienteValorHr * 1.5);
                        } else {
                          clienteValorHrNot = this.valorPlantao;
                        }
                        
                        for (let i =0; i < JSON.parse(itemTec.itemOS.atividades).length; i++ ){
                          var atividade = JSON.parse(itemTec.itemOS.atividades)[i];
                          if (atividade.esforcoNoturno) {
                            atividade.esforcos.forEach((item) => {
                              let dataEsforcoInicio = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                              let dataEsforcoFim    = moment(item.data + item.fim, 'DD/MM/YYYYHH:mm');
                              let minutes = moment.duration(dataEsforcoFim.diff(dataEsforcoInicio)).asMinutes();
    
                              horasTotalNot += itemTec.itemOS.esforcoNoturno;
                              valor += +(minutes * (clienteValorHrNot /60));
                            });
  
                          } /*else {
                            atividade.esforcos.forEach((item) => {
                              let dataEsforcoInicio = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                              let dataEsforcoFim    = moment(item.data + item.fim, 'DD/MM/YYYYHH:mm');
                              let minutes = moment.duration(dataEsforcoFim.diff(dataEsforcoInicio)).asMinutes();
    
                              horasTotal += itemTec.itemOS.esforcoNoturno;
                              valor += +(minutes * (itemTec.itemOS.clienteValorHr /60));                          
                            });
                          }*/
                          
                          
                        }                      
  
                      }
                    }
  
  
                    indexTec = this.getTecnicoIndex(this.relTecPorCliente, itemTec.tecnico);
                    if (indexTec == -1) {
                      this.relTecPorCliente.push({'tecnico': itemTec.tecnico, 'valorTotal': valor, 'horasTotal': horasTotal, 'horasTotalNot': horasTotalNot});  
                    } else {
                      this.relTecPorCliente[indexTec].valorTotal += +valor;
                      this.relTecPorCliente[indexTec].horasTotal += horasTotal;
                      this.relTecPorCliente[indexTec].horasTotalNot += horasTotalNot;
                    }
                  }

                  /*
                  if (tecCorrente != itemTec.tecnico){
                    console.log(itemTec.itemOS.codigo + " - " + valor);
                    this.relTecPorCliente.push({'tecnico': itemTec.tecnico, 'valorTotal': valor, 'horasTotal': horasTotal, 'horasTotalNot': horasTotalNot});  
                    indexTec++;
                  } else {     
                    this.relTecPorCliente[indexTec].valorTotal += +valor;
                    this.relTecPorCliente[indexTec].horasTotal += horasTotal;
                    this.relTecPorCliente[indexTec].horasTotalNot += horasTotalNot;
                  }
                  tecCorrente = itemTec.tecnico;  */
                }                              

              });

              this.mostraRelatorioPorTecnicoFatur = true;
              return resp
            //});
       });
  }

  public getTecnicoIndex(listaTec, tecnico) {
    let returnIndex = -1;
    listaTec.forEach((item, index) => {
      if (item.tecnico == tecnico) {
        returnIndex = index;
      }
    });
    return returnIndex;
  }

  // ################################
  // ########## ESTOQUE #############
  // ################################


  public geraRelatorioEstoque(form){
      let listaEstoqueAtual = [];

      this.mostrarRelatorioSemCliente = false;
      this.mostraRelatorioClienteSemOS = false;
      this.mostrarRelatorioConsolidado = false;
      this.mostrarRelatorioFatura = false;
      this.mostraRelatorioPorTecnico = false;
      this.mostraRelatorioPorTecnicoFatur = false;

      let dbRef = this.db.database.app.database(this.getBaseURL()).ref("/dados/estoque");
      let EstoqueObs = this.db.object(dbRef).snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
           let dataIni = moment(form.edtDataInicioRelTecnico.value, 'YYYY-MM-DDTHH:mm');
           let dataFim = moment(form.edtDataFimRelTecnico.value, 'YYYY-MM-DDTHH:mm');
           dataFim.hours(23);
           dataFim.minutes(59);

           this.totaisSaida = {'totalCusto': 0.0, 'totalVenda': 0.0, 'totalNeg': 0.0};
           this.totaisEntrada = {'totalCusto': 0.0, 'totalVenda': 0.0};
           this.relEstoqueSaida = [];
           this.relEstoqueEntrada = [];
           listaEstoqueAtual = [];
           Object.keys(result).map(key=> {
             if (result[key].historicoSaida) {
                for (let i =0; i < JSON.parse(result[key].historicoSaida).length; i++ ){
                  let histSaida = JSON.parse(result[key].historicoSaida)[i];

                  let dataSaida = moment(histSaida.data , 'YYYY-MM-DDTHH:mm');
                  if (dataSaida.isBetween(dataIni, dataFim)){
                    this.totaisSaida.totalCusto += histSaida.qtd * result[key].valorCusto;
                    this.totaisSaida.totalVenda += histSaida.qtd * result[key].valorVenda;
                    this.totaisSaida.totalNeg += histSaida.PrecoNegociado ? histSaida.qtd * histSaida.PrecoNegociado : histSaida.qtd * result[key].valorVenda;

                    this.relEstoqueSaida.push({'produto': result[key], 'valorVenda': result[key].valorVenda, 'valorCusto': result[key].valorCusto, 'data': histSaida, 'dataSaida': dataSaida.format('DD/MM/YYYY')});
                  }
                }
              }

              if (result[key].historicoEntrada) {
                 for (let i =0; i < JSON.parse(result[key].historicoEntrada).length; i++ ){
                   let histEntrada = JSON.parse(result[key].historicoEntrada)[i];

                   let dataEntrada = moment(histEntrada.data , 'YYYY-MM-DDTHH:mm');
                   if (dataEntrada.isBetween(dataIni, dataFim)){
                     this.totaisEntrada.totalCusto += histEntrada.qtd * result[key].valorCusto;
                     this.totaisEntrada.totalVenda += histEntrada.qtd * result[key].valorVenda;

                     let qtdTotal: Number = +histEntrada.qtd + +histEntrada.qtdExterna;
                     this.relEstoqueEntrada.push({'produto': result[key], 'valorCusto': result[key].valorCusto, 'valorVenda': result[key].valorVenda, 'data': histEntrada, 'qtdTotal': qtdTotal,'dataEntrada': dataEntrada.format('DD/MM/YYYY')});
                   }
                 }
               }

               listaEstoqueAtual.push(result[key]);
           });

           if (form.chkCompleto.checked){
             var xRelatorioEstoque = new EstoqueService(listaEstoqueAtual);
             listaEstoqueAtual.sort((n1,n2) => {
               if (n1.desc > n2.desc) {
                 return 1
               } else {
                 if (n1.desc < n2.desc) {
                   return -1
                 }
               }
               return 0
             });
             xRelatorioEstoque.savePDF();
           } else {
             this.relEstoqueSaida.sort((n1,n2) => {
               let n1Data = moment(n1.dataSaida, 'DD/MM/YYYY');
               let n2Data = moment(n2.dataSaida, 'DD/MM/YYYY');

               if (n1Data.isBefore(n2Data)) {
                 return 1
               } else {
                 if (n1Data.isAfter(n2Data)) {
                   return -1
                 } else {
                   return 0
                 }
               }
             });

             this.relEstoqueEntrada.sort((n1,n2) => {
               let n1Data = moment(n1.dataEntrada, 'DD/MM/YYYY');
               let n2Data = moment(n2.dataEntrada, 'DD/MM/YYYY');

               if (n1Data.isBefore(n2Data)) {
                 return 1
               } else {
                 if (n1Data.isAfter(n2Data)) {
                   return -1
                 }
               }
               return 0
             });

             this.mostraRelatorioEstoque = true;
           }

           EstoqueObs.unsubscribe();
         });
  }

  geraRelatorioListaOS(form) {
    let dataIni = moment(form.edtDataInicioRelListaOS.value, 'YYYY-MM-DDTHH:mm');
    let dataFim = moment(form.edtDataFomRelListaOS.value, 'YYYY-MM-DDTHH:mm');
    dataIni.hours(1);
    dataFim.hours(23);
    dataFim.minutes(59);

    let listaOS = [];
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref("/dados/ordemServico/abertas");
    let OSObservable = this.db.object(dbRef).snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      Object.keys(result).map(key=> {
        let dataOS = moment(result[key].dataVencimento, 'YYYY/MM/DDTHH:mm');

        if (dataOS.isBetween(dataIni, dataFim)){
          listaOS.push(result[key]);
        }
      });

      listaOS.sort((a,b) => {
        let DateA = moment(a.dataVencimento);
        let DateB = moment(b.dataVencimento);

        if (DateA.isBefore(DateB)) {
          return -1
        } else {
          if (DateB.isBefore(DateA)) {
            return 1
          } else {
            return 0;
          }
        }
      });

      let printContents = `<div style='font-size: 40px'>
          <img src="../../../../assets/logo-drrafael.jpeg" width="150px" height="100px" style="float:left;"/>
          
          <h3 style="text-align: center; font-size: 36px; padding-top: 20px">Consultas</h3>
          <br><br/>`;

      let popupWin;
      listaOS.forEach((item) => {
        let atividadesJSON = JSON.parse(item.atividades);
        
        let clienteData = this.getClienteDataPorCodigo(item.codCliente);
        printContents += '<b>Paciente</b>: ' + clienteData.data.nomeFantasia + '<br>';
        printContents += '<b>Data/Hora</b>: ' + moment(item.dataVencimento, 'YYYY/MM/DDTHH:mm').format('DD/MM/YYYY - HH:mm') + '<br>';
        printContents += '<b>Descrição</b>: ';
        let lines = item.descricao.split(/\r*\n/);
        lines.forEach(element => {
          printContents += element + '<br>';
        });
        printContents += '<b>Atividade</b>: ' + atividadesJSON[atividadesJSON.length-1].solucao;
        printContents += '<br>--------------------------------------------------------<br><br>';
      });
      printContents += '</div>'

      let pdfName = "Consultas " + moment().format('DD-MM HHmm');
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
            <title>${pdfName}</title>
            <style>
              //........Customized style.......
            </style>
          </head>
          <body onload="window.print();">
            ${printContents}
          </body>
        </html>`
      );
      popupWin.document.close();      

      OSObservable.unsubscribe();
    });
  }
  
  public logoff(){
    this.afAuth.auth.signOut();
  }
}
